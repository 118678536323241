<template>
  <div class="modal" v-if="show" @click.self="close">
    <div class="modal-content">
      <h3 class="modal-title">投诉详情</h3>
      <p><strong>投诉类型:</strong> {{ localComplaint.complaintType }}</p>
      <p><strong>投诉时间:</strong> {{ localComplaint.complaintTime }}</p>
      <p><strong>投诉医生:</strong> {{ localComplaint.doctorName }}</p>
      <p><strong>投诉内容:</strong> {{ localComplaint.complaintContent }}</p>
      <!-- Response Message Input -->
      <div class="response-message">
        <label for="responseMessage"><strong>回复:</strong></label>
        <textarea
            id="responseMessage"
            v-model="responseMessage"
            placeholder="请输入您的回复"
            rows="4"
        ></textarea>
      </div>
      <div class="status-selection">
        <label><strong>投诉状态:</strong></label>
        <input type="radio" id="started" value="STARTED" v-model="updatedStatus">
        <label for="started">未处理</label>
        <input type="radio" id="finished" value="FINISHED" v-model="updatedStatus">
        <label for="finished">处理完成</label>
      </div>
      <div class="modal-actions">
        <button @click="close">关闭</button>
        <button @click="submitUpdate">提交</button>
      </div>
      <!-- Hint Messages -->
      <div v-if="hintMessage" class="hint-message">{{ hintMessage }}</div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { post } from '@/utils/api';
import config from '../config'

export default {
  name: 'ComplaintDetails',
  props: {
    show: Boolean,
    complaint: Object,
  },
  emits: ['close', 'update:complaint'],
  setup(props, { emit }) {
    const localComplaint = ref({ ...props.complaint });
    const updatedStatus = ref(localComplaint.value.complaintStatus);
    const responseMessage = ref(localComplaint.value.responseMessage);
    const hintMessage = ref('');

    watch(() => props.complaint, (newComplaint) => {
      localComplaint.value = { ...newComplaint };
      updatedStatus.value = localComplaint.value.complaintStatus;
      responseMessage.value = localComplaint.value.responseMessage;
    });

    const close = () => {
      hintMessage.value = ''
      updatedStatus.value = localComplaint.value.complaintStatus;
      responseMessage.value = localComplaint.value.responseMessage;
      emit('close');
    };

    const submitUpdate = async () => {
      hintMessage.value = '正在提交....'; // Show submitting hint
      try {
        console.log(Number(localComplaint.value.complaintId));
        console.log(updatedStatus.value);
        post(config.handleComplaint, {
          complaintId: localComplaint.value.complaintId,
          status: updatedStatus.value,
          responseMessage: responseMessage.value,
          handleTime: new Date().toISOString(),
          patientId: localComplaint.value.patientId,
          doctorId: localComplaint.value.doctorId
        })
        .then(response => {
          // Handle successful login (e.g., save token, redirect user)
          if (response.data.code != 200)
          {
            hintMessage.value = '处理失败,请重试.'; // Error hint
            return;
          }

          localComplaint.value.complaintStatus = updatedStatus.value;
          localComplaint.value.responseMessage = responseMessage.value;
          console.log('emit complaint', localComplaint.value);
          emit('update:complaint', localComplaint.value); // Emit updated complaint
          hintMessage.value = '提交成功!'; // Success hint
        })
        .catch(error => {
          // Handle login error
          console.error('Handle complaint failed:', error);
        });

        /*
        const url = `${config.handleComplaint}?complaintId=
        ${localComplaint.value.complaintId}&complaintStatus=${updatedStatus.value}`
        const response = await post(url);
        if (response.status === 200) {
          console.log(response);
          localComplaint.value.complaintStatus = updatedStatus.value;
          emit('update:complaint', localComplaint.value); // Emit updated complaint
          hintMessage.value = 'Submission successful!'; // Success hint
        } else {
          hintMessage.value = 'Failed to update complaint status. Please try again.'; // Error hint
        }
         */
      } catch (error) {
        console.error('Failed to update complaint status:', error);
        hintMessage.value = 'Error occurred during submission. Please try again.'; // Error hint
      }
      //setTimeout(() => hintMessage.value = '', 5000); // Clear hint after 3 seconds
      //updatedStatus.value = localComplaint.value.complaintStatus;
      //close();
    };

    return {
      localComplaint,
      updatedStatus,
      responseMessage,
      hintMessage,
      close,
      submitUpdate,
    };
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-title {
  margin-bottom: 15px;
  font-size: 22px;
  color: #007bff;
}

.status-selection {
  margin-bottom: 15px;
}

.status-selection label {
  margin-right: 10px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions button:first-child {
  background: #ccc;
  color: #333;
}

.modal-actions button:last-child {
  background: #007bff;
  color: white;
}

.hint-message {
  margin-top: 15px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #007bff;
  background-color: #e3f2fd; /* Light blue background */
  border: 1px solid #b3e5fc; /* Slightly darker blue border */
  border-radius: 4px;
}

.response-message {
  margin-bottom: 15px;
}

.response-message label {
  display: block;
  margin-bottom: 5px;
}

.response-message textarea {
  width: 90%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
}
</style>
