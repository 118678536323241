<template>
  <div class="risk-container">
    <h2 class="title">风险等级</h2>
    <div
        v-for="risk in riskLevels"
        :key="risk.level"
        class="risk-card"
        :style="{ borderColor: risk.color }"
    >
      <div class="risk-header" @click="toggleRisk(risk.level)">
        <span :class="['triangle', isExpanded(risk.level) ? 'expanded' : 'collapsed']"></span>
        {{ getriskLevelText(risk.level) }}
      </div>
      <transition name="fade">
        <ul v-if="isExpanded(risk.level)" class="risk-list">
          <li
              v-for="person in filteredPeople(risk.value)"
              :key="person.patientName"
          >
            <p @click="toggleComplaints(person.patientId)" class="person-name">
              {{ person.patientName }}
            </p>
            <transition name="fade">
              <ul v-if="isComplaintsExpanded(person.patientId)" class="complaints-list">
                <li v-for="complaint in complaints[person.patientId]"
                    :key="complaint.id"
                    class="complaint-item"
                    @click="showComplaintDetails(complaint)">
                  <strong class="complaint-status">{{ getStatusText(complaint.complaintStatus) }}</strong>
                  <span class="complaint-type">{{ complaint.complaintType }}</span>
                  <span class="complaint-time">{{ complaint.complaintTime }}</span>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </transition>
    </div>
  </div>
  <ComplaintDetails
      :show="isModalVisible"
      :complaint="selectedComplaint"
      @close="isModalVisible = false"
      @update:complaint="handleComplaintUpdate"
  />
</template>

<script>
import { get } from '@/utils/api';
import { ref, reactive, onMounted } from 'vue';
import config from '../config'
import ComplaintDetails from '../components/ComplaintDetails.vue';

export default {
  name: 'RiskPatient',
  components: {
    ComplaintDetails,
  },
  methods: {
    getStatusText(status) {
      switch (status.toLowerCase()) {
        case 'finished':
          return '处理完成';
        case 'started':
          return '未处理';
        default:
          return status;
      }
    },
    getriskLevelText(riskLevel) {
      switch (riskLevel.toLowerCase()) {
        case 'high':
          return '高风险';
        case 'medium':
          return '中风险';
        case 'low':
          return '低风险';
        default:
          return riskLevel;
      }
    }
  },
  setup() {
    const people = ref([]);
    const expandedRiskLevels = ref([]);
    const expandedComplaints = ref([]);
    const complaints = reactive({});
    const isModalVisible = ref(false);
    const selectedComplaint = ref(null);

    const riskLevels = [
      { level: 'High', color: '#ee6960', value: 3 }, // Red
      { level: 'Medium', color: '#ffeb3b', value: 2 }, // Yellow
      { level: 'Low', color: '#59e65e', value: 1 }, // Green
    ];

    const fetchPeople = async () => {
      try {
        const response = await get(config.findOverallRiskPatientInfos);
        if (response.status === 200) {
          people.value = await response.data;
        } else {
          console.error('Failed to fetch people list');
        }
      } catch (error) {
        console.error('Error fetching people:', error);
      }
    };

    const fetchComplaints = async (personId) => {
      try {
        const response = await get(config.getComplaintByPatientId + `/${personId}`);
        if (response.status === 200) {
          complaints[personId] = response.data.filter(c => c.complaintStatus === "STARTED").map(complaint => ({
            ...complaint,
            complaintTime: formatTime(complaint.complaintTime)
          })).map(complaint => ({
            ...complaint,
            complaintStatus: complaint.complaintStatus.toUpperCase()
          })).sort((a, b) => new Date(b.complaintTime) - new Date(a.complaintTime));
        } else {
          console.error('Failed to fetch complaints');
        }
      } catch (error) {
        console.error('Error fetching complaints:', error);
      }
    };
    const formatTime = (time) => {
      return time.replace(/T/, ' ').split('.')[0];
    };
    const toggleRisk = (riskLevel) => {
      const index = expandedRiskLevels.value.indexOf(riskLevel);
      if (index > -1) {
        expandedRiskLevels.value.splice(index, 1);
      } else {
        expandedRiskLevels.value.push(riskLevel);
      }
    };

    const isExpanded = (riskLevel) => {
      return expandedRiskLevels.value.includes(riskLevel);
    };

    const toggleComplaints = (personId) => {
      const index = expandedComplaints.value.indexOf(personId);
      if (index > -1) {
        expandedComplaints.value.splice(index, 1);
      } else {
        expandedComplaints.value.push(personId);
        if (!complaints[personId]) {
          fetchComplaints(personId);
        }
      }
    };

    const isComplaintsExpanded = (personId) => {
      return expandedComplaints.value.includes(personId);
    };

    const filteredPeople = (riskLevel) => {
      return people.value.filter((person) => person.riskLevel === riskLevel);
    };

    const showComplaintDetails = (complaint) => {
      selectedComplaint.value = complaint;
      isModalVisible.value = true;
    };

    const handleComplaintUpdate = (updatedComplaint) => {
      console.log('updatedComplaint', updatedComplaint);
      const patientId = updatedComplaint.patientId;
      const complaintIndex = complaints[patientId].findIndex(c => c.complaintId === updatedComplaint.complaintId);

      console.log('complaintIndex', complaintIndex);
      if (complaintIndex !== -1) {
        // Update the specific complaint with the new data
        complaints[patientId][complaintIndex] = updatedComplaint;
      }

      // Optionally, close the modal or perform any additional actions
      // isModalVisible.value = false;
    };

    onMounted(() => {
      fetchPeople();
    });

    return {
      people,
      expandedRiskLevels,
      expandedComplaints,
      isModalVisible,
      selectedComplaint,
      complaints,
      riskLevels,
      toggleRisk,
      isExpanded,
      toggleComplaints,
      isComplaintsExpanded,
      filteredPeople,
      showComplaintDetails,
      handleComplaintUpdate
    };
  },
};
</script>

<style scoped>
.risk-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.risk-card {
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, border-color 0.2s;
}

.risk-card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-color: #ddd;
}

.risk-header {
  padding: 15px;
  color: #333;
  display: flex;
  align-items: center;
  font-weight: bold;
  position: relative;
  background: #f9f9f9;
}

.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  margin-right: 10px;
  transition: border-color 0.3s;
}

.triangle.collapsed {
  border-top: 12px solid #333;
}

.triangle.expanded {
  border-bottom: 12px solid #333;
}

.risk-list {
  list-style-type: none;
  padding: 10px 20px;
  margin: 0;
  background: #f0f0f0;
}

.risk-list li {
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.risk-list li:last-child {
  border-bottom: none;
}

.complaints-list {
  list-style-type: none;
  padding: 5px 20px;
  margin: 5px 0 0 0;
  background: #f7f7f7;
}

.complaint-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

.complaint-item:last-child {
  border-bottom: none;
}

.complaint-status {
  width: 100px; /* Fixed width for status */
  text-transform: uppercase;
  font-weight: bold;
}

.complaint-id {
  width: 100px; /* Fixed width for ID */
  color: #555;
  text-align: center;
}

.complaint-type {
  width: 150px; /* Fixed width for type */
  color: #333;
  text-align: left;
}

.complaint-time {
  width: 180px; /* Fixed width for time */
  color: #888;
  text-align: right;
  font-size: 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.person-name {
  margin: 0;
  cursor: pointer;
  font-weight: bold;
}

.person-name:hover {
  color: #007bff;
}
</style>
