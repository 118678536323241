<template>
  <div class="report-container">
    <h2 class="title">风险病人报告</h2>
    <div ref="chart" class="chart"></div>
    <h2 class="title">投诉分布</h2>
    <div ref="chart1" class="chart"></div>
    <h2 class="title">投诉医生分布</h2>
    <div ref="chart2" class="chart"></div>
    <h2 class="title">投诉科室分布</h2>
    <div ref="chart3" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { onMounted, ref } from 'vue';
import { get } from '@/utils/api';  // Adjust this import according to your project structure
import config from '../config';

export default {
  name: 'RiskPatientReport',
  setup() {
    const chart = ref(null);
    const chart1 = ref(null);
    const chart2 = ref(null);
    const chart3 = ref(null);

    const fetchPatientData = async () => {
      try {
        const response = await get(config.findOverallRiskPatientInfos); // Adjust API endpoint as needed
        if (response.status === 200) {
          const data = response.data;
          console.log(data)
          const riskCounts = data.reduce(
              (acc, patient) => {
                if (patient.riskLevel === 1){
                  acc['Low'] ++;
                }
                else if (patient.riskLevel === 2){
                  acc['Medium'] ++;
                }
                else if (patient.riskLevel === 3 ){
                  acc['High'] ++;
                }
                return acc;
              },
              { High: 0, Medium: 0, Low: 0 }
          );
          renderChart(riskCounts);
        } else {
          console.error('Failed to fetch patient data');
        }
      } catch (error) {
        console.error('Error fetching patient data:', error);
      }
    };

    const renderChart = (riskCounts) => {
      const chartInstance = echarts.init(chart.value);
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '10%',
        },
        series: [
          {
            name: '风险等级',
            type: 'pie',
            radius: '50%',
            data: [
              { value: riskCounts.High, name: '高风险', itemStyle: { color: '#ee6960' } },  // Red
              { value: riskCounts.Medium, name: '中风险', itemStyle: { color: '#ffeb3b' } },  // Yellow
              { value: riskCounts.Low, name: '低风险', itemStyle: { color: '#59e65e' } },  // Green
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      chartInstance.setOption(option);
    };

    const fetchComplaintType = async () => {
      try {
        const response = await get(config.findAllComplaints); // Adjust API endpoint as needed
        if (response.status === 200) {
          const data = response.data;
          console.log(data)
          const types = data.reduce(
              (acc, complaint) => {
                const type = complaint.complaintType;
                if (!acc[type]) {
                  acc[type] = 0;
                }
                acc[type]++;
                return acc;
              },
              { }
          );
          renderComplaintChart(types);

          const complaintDoctors = data.reduce(
              (acc, complaint) => {
                const doctorName = complaint.doctorName;
                if (!acc[doctorName]) {
                  acc[doctorName] = 0;
                }
                acc[doctorName]++;
                return acc;
              },
              { }
          );
          renderComplaintDoctorChart(complaintDoctors);

          const complaintDepartments = data.reduce(
              (acc, complaint) => {
                const department = complaint.department;
                if (!acc[department]) {
                  acc[department] = 0;
                }
                acc[department]++;
                return acc;
              },
              { }
          );
          renderComplaintDepartmentChart(complaintDepartments);
        } else {
          console.error('Failed to fetch complaint data');
        }
      } catch (error) {
        console.error('Error fetching complaint data:', error);
      }
    };

    const renderComplaintChart = (feedbackCounts) => {
      const chartInstance = echarts.init(chart1.value);
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '10%',
        },
        series: [
          {
            name: '投诉类型',
            type: 'pie',
            radius: '50%',
            data: Object.keys(feedbackCounts).map(type => ({
              value: feedbackCounts[type],
              name: type,
              itemStyle: {
                color: getColorForType(type),  // Optional function to define colors per type
              },
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      chartInstance.setOption(option);
    };

    const renderComplaintDoctorChart = (complaintDoctors) => {
      const chartInstance = echarts.init(chart2.value);
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '10%',
        },
        series: [
          {
            name: '投诉医生',
            type: 'pie',
            radius: '50%',
            data: Object.keys(complaintDoctors).map(doctorName => ({
              value: complaintDoctors[doctorName],
              name: doctorName,
              itemStyle: {
                color: getColorForDoctor(doctorName),  // Optional function to define colors per type
              },
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      chartInstance.setOption(option);
    };

    const renderComplaintDepartmentChart = (complaintDepartments) => {
      const chartInstance = echarts.init(chart3.value);
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '10%',
        },
        series: [
          {
            name: '投诉科室',
            type: 'pie',
            radius: '50%',
            data: Object.keys(complaintDepartments).map(department => ({
              value: complaintDepartments[department],
              name: department,
              itemStyle: {
                color: getColorForDepartment(department),  // Optional function to define colors per type
              },
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      chartInstance.setOption(option);
    };

    const getColorForType = (type) => {
      const colorMap = {
        '医生额外开药、开检查': '#ee6960', // Red
        '医生态度不好': '#ffeb3b', // Yellow
        '手术事故': '#59e65e', // Green
        '未告知手术风险': '#ffa500', // Orange
        '其他': '#0000FF', // Blue
        // Add more types and colors as needed
      };
      return colorMap[type] || '#cccccc'; // Default color if type is not in the map
    };

    const getColorForDoctor = (doctorName) => {
      const colorMap = {
        '吴芳': '#ee6960', // Red
        '钮佳俊医生': '#ffeb3b', // Yellow
        '罗勰医生': '#59e65e', // Green
        '聂勇刚医生': '#ffa500', // Orange
        '秦浚博医生': '#0000FF', // Blue
        '顾濛濛医生': '#00FFFF', // Cyan
        // Add more types and colors as needed
      };
      return colorMap[doctorName] || '#cccccc'; // Default color if type is not in the map
    };

    const getColorForDepartment = (department) => {
      const colorMap = {
        '口腔科': '#ee6960', // Red
        '心脏内科': '#ffeb3b', // Yellow
        '神经内科': '#59e65e', // Green
        // Add more types and colors as needed
      };
      return colorMap[department] || '#cccccc'; // Default color if type is not in the map
    };

    onMounted(() => {
      fetchPatientData();
      fetchComplaintType();
    });

    return {
      chart,
      chart1,
      chart2,
      chart3
    };
  },
};
</script>

<style scoped>
.report-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.chart {
  width: 100%;
  height: 400px;
}
</style>
