<template>
  <div class="login-container">
    <h2>循仁科技</h2>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="username">用户名</label>
        <input
            type="text"
            id="username"
            v-model="username"
            placeholder="用户名"
            required
        />
      </div>
      <div class="form-group">
        <label for="password">密码</label>
        <input
            type="password"
            id="password"
            v-model="password"
            placeholder="密码"
            required
        />
      </div>
      <button type="submit">登录</button>
    </form>
  </div>
  <!-- Add the links here -->
  <div class="footer-links">
    <a href="https://beian.miit.gov.cn/" target="_blank">您的备案号</a>
    <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2024072505号</a>
  </div>
</template>

<script>
import { post } from '@/utils/api'
import config from '../config';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async handleLogin() {
      try {
        post(config.loginUrl, {
          username: this.username,
          password: this.password,
          skipVerificationCode: true,
          source: 'webPage'
        })
        .then(response => {
          // Handle successful login (e.g., save token, redirect user)
          if (response.data.code != 200)
          {
            alert('用户名密码不正确');
            return;
          }

          // Handle successful login (e.g., redirect, store token, etc.)
          alert('登录成功');

          localStorage.setItem('authToken', response.data.token); // Save token
          this.$emit('login'); // Emit login event

          // Example: Redirect to the dashboard
          this.$router.push(config.riskPatient);
        })
        .catch(error => {
          // Handle login error
          console.error('Login failed:', error);
        });
      } catch (error) {
        // Handle error (e.g., show error message)
        alert('Login failed. Please check your credentials.');
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 40px auto 0;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #369d77;
}
/* Footer links positioned at the bottom of the page */
.footer-links {
  position: absolute;
  bottom: 10px; /* Adjust this value to your preference */
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  background-color: #f5f5f5;
}

.footer-links a {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

</style>
