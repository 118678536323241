<template>
  <div class="report-container">
    <h2 class="title">反馈报告</h2>
    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { onMounted, ref } from 'vue';
import { get } from '@/utils/api';  // Adjust this import according to your project structure
import config from '../config';

export default {
  name: 'FeedbackReport',
  setup() {
    const chart = ref(null);

    const fetchFeedbackData = async () => {
      try {
        const response = await get(config.findAllFeedbackInfos); // Adjust API endpoint as needed
        if (response.status === 200) {
          const data = response.data;
          console.log(data)
          const types = data.reduce(
              (acc, feedback) => {
                const type = feedback.situationType;
                if (!acc[type]) {
                  acc[type] = 0;
                }
                acc[type]++;
                return acc;
              },
              { }
          );
          renderChart(types);
        } else {
          console.error('Failed to fetch feedback data');
        }
      } catch (error) {
        console.error('Error fetching feedback data:', error);
      }
    };

    const renderChart = (feedbackCounts) => {
      const chartInstance = echarts.init(chart.value);
      const option = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '10%',
        },
        series: [
          {
            name: '反馈类型',
            type: 'pie',
            radius: '50%',
            data: Object.keys(feedbackCounts).map(type => ({
              value: feedbackCounts[type],
              name: type,
              itemStyle: {
                color: getColorForType(type),  // Optional function to define colors per type
              },
            })),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
      chartInstance.setOption(option);
    };

    const getColorForType = (type) => {
      const colorMap = {
        '术后疼痛': '#ee6960', // Red
        '术后肿胀': '#ffeb3b', // Yellow
        '术后出血': '#59e65e', // Green
        // Add more types and colors as needed
      };
      return colorMap[type] || '#cccccc'; // Default color if type is not in the map
    };

    onMounted(() => {
      fetchFeedbackData();
    });

    return {
      chart,
    };
  },
};
</script>

<style scoped>
.report-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.chart {
  width: 100%;
  height: 400px;
}
</style>
