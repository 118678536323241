// src/config.js
export default {
    apiBaseUrl: 'https://www.qixietracker.com/api',
    //apiBaseUrl: 'http://127.0.0.1:8080/api',
    loginUrl: '/login',
    findOverallRiskPatientInfos: '/yoshitest/findOverallRiskPatientInfos',
    getComplaintByPatientId: '/complaint/getComplaints',
    updateComplaintStatus: '/yoshitest/updateComplaintStatus',
    handleComplaint: '/complaint/handleComplaint',
    yoshiindex: '/yoshiindex',
    riskPatient: '/riskPatient',
    findAllFeedbackInfos: '/feedback/getAllFeedbacks',
    findAllComplaints: '/complaint/getAllComplaints',
    findHandledComplaintPatients: 'complaint/getHandledComplaintPatients'
};