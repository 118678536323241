// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import RiskPatientList  from "./components/RiskPatientList.vue";
import UserLogin  from "./components/Login.vue";
import RiskPatientReport from "@/components/RiskPatientReport.vue";
import FeedbackReport from "@/components/FeedbackReport.vue";
import yoshiindex  from "./components/yoshiindex.vue";
import HandledComplaints from "@/components/HandledComplaints.vue";


const routes = [
    {
        path: '/',
        component: UserLogin,
    },
    {
        path: '/riskPatient',
        component: RiskPatientList,
    },
    {
        path: '/yoshiindex',
        component: yoshiindex,
    },
    {
        path:'/riskPatientReport',
        component: RiskPatientReport,
    },
    {
        path:'/feedbackReport',
        component: FeedbackReport,
    },
    {
        path:'/handledComplaints',
        component: HandledComplaints,
    }
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});
export default router;
