// src/components/yoshiindex.vue
<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'yoshiIndex',
};
</script>
