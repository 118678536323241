import axios from 'axios';
import config from '../config'

// Base URL for the API
const API_BASE_URL = config.apiBaseUrl; // Update with your actual API base URL

// Create an axios instance with default settings
const apiClient = axios.create({
    baseURL: API_BASE_URL,
    timeout: 10000, // Set a timeout for requests (optional)
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add an interceptor to include the auth token in requests, except login requests
apiClient.interceptors.request.use(config => {
    // Check if the request URL does not match the login endpoint
    if (!config.url.includes('/login')) {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Common function for GET requests
export const get = (url, params = {}) => {
    return apiClient.get(url, { params })
        .then(response => response)
        .catch(error => {
            console.error('GET request error:', error);
            throw error; // Forward the error for further handling
        });
};

// Common function for POST requests
export const post = (url, data = {}) => {
    return apiClient.post(url, data)
        .then(response => response)
        .catch(error => {
            console.error('POST request error:', error);
            throw error; // Forward the error for further handling
        });
};
